import React, { createRef, useEffect, useState } from "react";
import * as QRCode from 'easyqrcodejs'
import deviceStats from "../services/device-stats";
import { CONSTANTS } from "../constants/display";
import "./PairingScreen.css";
import Warning from "../common/Warning";
// const APP_BASE_URL = process.env.REACT_APP_BASE_URL || "http://localhost:1337";

function PairingScreen({
    show = false,
    pairingCode
}) {

    // Commenting out QR code for now

    // const codeRef = createRef();
    // const qrVal = pairingCode

    // useEffect(() => {
    //     if (codeRef.current) {
    //         new QRCode(codeRef.current, {
    //             text: qrVal,
    //             width: 160,
    //             height: 160,
    //             correctLevel : QRCode.CorrectLevel.L,
    //             drawer: 'svg'
    //         });
    //     }
    // }, [qrVal]);

    const [storageWarning, setStorageWarning] = useState(null);

    useEffect(() => {
        deviceStats.getStorage().then(({ usage, quota }) => {
            console.log(quota, parseInt(process.env.REACT_APP_STORAGE_QUOTA_MIN), quota <= process.env.REACT_APP_STORAGE_QUOTA_MIN)
            // console.log(usage)
            if (quota === 'Unknown' || quota <= parseInt(process.env.REACT_APP_STORAGE_QUOTA_MIN)) {
                setStorageWarning("This device has limited storage available. Some media may not display.");
            }
        })
    }, []);

    if (!pairingCode) return null

    return (
        <div className={"pairing-screen" + (show ? " pairing-screen--show" : "")}>
            <div className="pairing-screen_content">
                <h1 className='pairing-screen_title'>Pair this display</h1>
                <p className='pairing-screen_instructions'>Go to the 'Frames' section in the Zeroframe app to pair this display.</p>
                {/* <div className="pairing-screen_qr">
                    <div ref={codeRef}></div>
                </div> */}
                <h2 className="pairing-screen_code mono">{pairingCode}</h2>
                {storageWarning && (
                    <Warning warningText={storageWarning} />
                )}
            </div>
            <div className="pairing-screen_zeroframe mono">{CONSTANTS.ZEROFRAME}</div>
        </div>
    );
}

export default PairingScreen;
