import React from "react";
import { CONSTANTS } from "../constants/display";
import Warning from "./Warning";
import logoLight from "../assets/svgs/logo-light.svg";
import "./StatusScreen.css";

function StatusScreen({
    show = false,
    frameNotFound = false,
    waitingForArtwork = false,
    unsupportedFormat = false,
    warningCopy = "",
    frameName,
    artworkTitle,
}) {
    return (
        <div className={"status-screen" + (show ? " status-screen--show" : "")}>
            <div className="status-screen_content">
                {warningCopy && (
                    <Warning warningText={warningCopy} />
                )}
                {waitingForArtwork && (
                    <>
                        <h2>{frameName}</h2>
                        <div className="pulse">{CONSTANTS.WAITING_FOR_ARTWORK}</div>
                    </>
                )}
                {frameNotFound && (
                    <>
                        <img className="pulse" src={logoLight} alt="loading..." />

                        <h2>{CONSTANTS.NO_FRAME}</h2>
                    </>
                )}
                {unsupportedFormat && (
                    <>
                        <img className="pulse" src={logoLight} alt="loading..." />

                        <h2>Unable to display: {artworkTitle}</h2>

                        <div>Unrecognized media type.</div>
                    </>
                )}
            </div>

            <div className="status-screen_zeroframe">{CONSTANTS.ZEROFRAME}</div>
        </div>
    );
}

export default StatusScreen;
