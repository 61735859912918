import { init, identify, Identify, setUserId, track, flush, reset } from '@amplitude/analytics-browser';

const analytics = {
    init: function(apiKey) {
        // reset()
        // console.log('initializing amplitude')
        init(apiKey)
    },
    setUserProp: function(key, value) {
        const identifyObj = new Identify();
        identifyObj.set(key, value);
        identify(identifyObj);
    },
    track: function(eventName, props = {}) {
        return track(eventName, props).promise;
    },
    setUserId: function(userId) {
        setUserId(userId)
    },
    flush: function() {
        flush()
    }
}

export default analytics