import React from 'react'
import PropTypes from 'prop-types'
import warningIcon from "../assets/svgs/warning-icon.svg";
import "./Warning.css";

function Warning(props) {
    const { warningText } = props

    return (
        <div className="warning">
            <img className="warning__icon" src={warningIcon} alt="Warning" />
            <span className="warning__copy">{warningText}</span>
        </div>
    )
}


Warning.propTypes = {
    warningText: PropTypes.node,
}

Warning.defaultProps = {}

export default Warning