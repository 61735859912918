import React, { useState, useEffect } from "react";
import StatusScreen from "../common/StatusScreen";

const UnsupportedFormat = ({ title, onReadyHandler, onErrorHandler }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    onReadyHandler();
    setShow(true);
  }, [onReadyHandler]);
  return (
    <StatusScreen show={show} unsupportedFormat={true} artworkTitle={title} />
  );
};

export default UnsupportedFormat;
