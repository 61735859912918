import React from "react";
// import { CONSTANTS } from "../constants/display";
import "./Controls.css";

function Controls({
    show = false,
    doReset,
    isConnected,
    frameName
}) {
    const handleResetClick = (e) => {
        // e.preventDefault();
        e.stopPropagation();
        doReset();
    }
    const connClass = isConnected ? 'controls_connected_indicator--connected' : 'controls_connected_indicator--disconnected'
    return (
        <div className={"controls" + (show ? " controls--show" : "")}>
            <div className="controls_content">
                <div className="controls_item controls_pill">
                    <span className={`controls_connected_indicator ${connClass}`}></span>
                    <span className="controls_name">{frameName}</span>
                </div>
                <button className="controls_item controls_button" onClick={handleResetClick}>Reset frame</button>
            </div>
        </div>
    );
}

export default Controls;
