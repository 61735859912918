import React from 'react';
import './TrialModeBox.css'; // Import CSS file for styling
import { getDateDifferenceIndays } from '../lib/util';

const TrialModeBox = ({subPeriodEnd}) => {
    const daysUntilTrialEnd = getDateDifferenceIndays(new Date(subPeriodEnd));
  return (
    <div className="trial-mode-box">
      <div>
        <p>
          You have { daysUntilTrialEnd >= 0 ? daysUntilTrialEnd : 0 } days left on your trial
        </p>
        <p>
          Click ‘Start plan’ on your dashboard to remove this banner
        </p>
      </div>
    </div>
  );
};

export default TrialModeBox;
