import React, { useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

const Image = props => {
    const componentRef = useRef();
    const imgEl = useRef(null);
    useEffect(() => {
        componentRef.current = props.retries
    }, [props.retries]);

    const doRetry = useCallback((img) => {
        img.onerror = null;
        img.src = props.src;
        componentRef.current = componentRef && componentRef.current && componentRef.current - 1;
    }, [props]);

    const handleError = useCallback(({ currentTarget }) => {
        if (componentRef && componentRef.current && componentRef.current > 0) {
            setTimeout(() => {
                doRetry(currentTarget);
            }, props.retryDelay);
        }
    }, [props, doRetry]);

    const handleLoad = useCallback(() => {
        props.onLoad();
    }, [props]);

    // This doesn't seem necessary as it doesn't actually retry the image if the initial request is in flight
    // useInterval(() => {
    //     if (!isLoaded) {
    //         console.log('img not loaded after 1s, retrying')
    //         doRetry(imgEl.current)
    //     }
    // }, 1000);

    return (
        <img
            ref={imgEl}
            className="image"
            alt={props.alt}
            src={props.src}
            onLoad={handleLoad}
            onError={handleError}
            style={props.style}
        />
    )
}

Image.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    onLoad: PropTypes.func.isRequired,
    retries: PropTypes.number,
    retryDelay: PropTypes.number,
    style: PropTypes.object,
}

Image.defaultProps = {
    retries: 3,
    retryDelay: 1000,
}

export default Image