export const toggleFullscreen = () => {
  if (!document.fullscreenElement) {
    document.documentElement.requestFullscreen().catch(err => {
      console.log(`Error attempting to enable fullscreen mode: ${err.message} (${err.name})`);
    });
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }
};

export const preloadImage = (src, cb, errcb, timeout = 30) => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => {
      if (cb) {
        cb();
      }
      resolve(img);
    };
    img.onerror = () => {
      if (errcb) {
        errcb();
      }
      reject(img);
    };
    img.src = src;

    setTimeout(() => {
      reject(img);
    }, timeout * 1000);
  });
};

export const getFrameDataArtworks = (frameData) => {
  const newArtworks = []
  if (frameData?.artwork) {
    newArtworks.push(frameData.artwork);
  }
  if (frameData?.artworkCollection?.artworks) {
    newArtworks.push(...frameData.artworkCollection.artworks.map(a => a.artwork));
  }
  return newArtworks;
};

export const hasFrameDataChanged = (oldFrame, newFrame) => {
  if (
    oldFrame?.artworkCollection?.interval !==
    newFrame?.artworkCollection?.interval
  ) {
    return true;
  }

  if (
    oldFrame?.rotation !== newFrame?.rotation
  ) {
    return true;
  }

  if (
    oldFrame?.name !== newFrame?.name
  ) {
    return true;
  }

  if (
    oldFrame?.plaque !== newFrame?.plaque
  ) {
    return true;
  }

  if (
    oldFrame?.plaqueSize !== newFrame?.plaqueSize
  ) {
    return true;
  }

  if (
    oldFrame?.pairingCode !== newFrame?.pairingCode
  ) {
    return true;
  }

  if (
    JSON.stringify(getFrameDataArtworks(oldFrame)) !==
    JSON.stringify(getFrameDataArtworks(newFrame))
  ) {
    return true;
  }

  return false;
};

export const hasArtworkChanged = (oldFrame, newFrame) => {
  if (
    oldFrame?.artworkCollection?.interval !==
    newFrame?.artworkCollection?.interval
  ) {
    return true;
  }

  if (
    JSON.stringify(getFrameDataArtworks(oldFrame)) !==
    JSON.stringify(getFrameDataArtworks(newFrame))
  ) {
    return true;
  }

  return false;
};


/**
 * to calculate the height of the artwork wrap
 * @function artworkWrapHeight
 * @param artwork artwork details display Type Fit or Fill
 * @param innerHeight viewport height
 */

export const artworkWrapHeight = (artwork, innerHeight) => {
  return `${innerHeight * .9}px`;
  // return (artwork?.display === 'fill' && !(artwork?.artist || artwork?.title || artwork?.qrCodeUrl)) ? '100%' : (100 - ((((innerHeight / 9) + 64) * 100) / innerHeight))+ '%';
}


/**
 * 
 * @param {*} periodEndDate 
 * @returns days
 */
export const getDateDifferenceIndays = (periodEndDate)=>{
  const todayDate = new Date();
  periodEndDate.setHours(0,0,0,0);
  todayDate.setHours(0,0,0,0);
  const difference= periodEndDate.getTime() - todayDate.getTime();
  return Math.round( difference / (1000 * 60 * 60 * 24));
}
